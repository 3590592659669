<template>
  <div class="contact" :style="cssVars">
    <privacy-policy v-model="showPrivacyPolicy" :boxOffice="boxOffice"/>

    <v-toolbar dark>
      <span class="ml-auto mr-4 cursor-pointer" v-if="boxOffice" @click="contact = true">Contact</span>
      <span v-if="boxOffice" class="cursor-pointer ml-4 mr-auto"><a href="https://openstage.live/privacy" target="_blank" class="white" title="Opens in a new tab">Privacy</a></span>
    </v-toolbar>

    <v-dialog v-if="boxOffice" v-model="contact" persistent max-width="600px">
      <v-form ref="form">
      <v-card :style="cssVars">
        <v-card-title>{{boxOffice.name}}              
          <v-spacer/>
          <v-icon large @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p class="mt-6">Do you have a question for us, or would you like us to send you your tickets again?</p>
          <div class="d-flex">
            <Button @click="questionContact = true; ticketsContact = false">Question</Button>
            <v-spacer/>
            <Button @click="questionContact = false; ticketsContact = true">Send Tickets</Button>
          </div>
          <div v-if="questionContact">
            <p class="mt-6">Please complete this form, or email us at {{boxOffice.email}}.</p>
            <v-text-field v-model="name" label="Your Name" required :rules="[utils.requiredRule]"></v-text-field>
            <v-text-field v-model="email" label="Email" required :rules="[utils.requiredRule, utils.emailRule]"></v-text-field>
            <v-textarea v-model="message" label="Message" required :rules="[utils.requiredRule]"></v-textarea>
          </div>
          <div v-if="ticketsContact">
            <p class="mt-6">Please enter your email address, any tickets you have for events that have not yet happened will be emailed to you.</p>
            <v-text-field v-model="email" label="Email" required :rules="[utils.requiredRule, utils.emailRule]"></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions v-if="questionContact">
          <Button class="ml-2 mb-2" @click="send" :loading="sending">Send</Button>
        </v-card-actions>
        <v-card-actions v-if="ticketsContact">
          <Button class="ml-2 mb-2"  @click="sendTickets" :loading="sending">Send</Button>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="boxOffice" v-model="contactConfirm" persistent max-width="400px">
      <v-form ref="form">
      <v-card>
        <v-card-title>{{boxOffice.name}}              
          <v-spacer/>
          <v-icon large @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p class="mt-6">Thank you, we will be in touch shortly.</p>
        </v-card-text>
        <v-card-actions>
          <Button @click="close()">Close</Button>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-if="boxOffice" v-model="sentTicketsConfirm" persistent max-width="400px">
      <v-form ref="form">
      <v-card>
        <v-card-title>{{boxOffice.name}}              
          <v-spacer/>
          <v-icon large @click="close()">mdi-close</v-icon>
        </v-card-title>
        <v-card-text>
          <p class="mt-6" v-if="emailSent">Thank you, your tickets are on their way.</p>
          <p class="mt-6" v-else>Sorry, we couldn't find any tickets for upcomming events with this email address.</p>
        </v-card-text>
        <v-card-actions v-if="emailSent">
          <Button tile outlined @click="close()">Close</Button>
        </v-card-actions>
        <v-card-actions v-else>
          <Button @click="sentTicketsConfirm = false; questionContact = true; ticketsContact = false">Contact Us</Button>
          <v-spacer/>
          <Button @click="sentTicketsConfirm = false; questionContact = false; ticketsContact = true">Try a different email</Button>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>

  </div>
</template>
  
<script>
  import Api from '@/services/Api.js'; 
  import Utils from '@/services/Utils.js'
  import Store from '@/services/Store.js';
  export default {
    components: {
      PrivacyPolicy: () => import('./PrivacyPolicy'),
      Button: () => import('./Button'),
    },  

    props: {
      boxOffice: null,
      event: null,
    },

    data () {
      return {
        contact: false,
        emailSent: false,
        sending: false,
        sentTicketsConfirm: false,
        questionContact: false,
        ticketsContact: false,
        contactConfirm: false,
        name: "",
        email: "",
        message: "",
        showPrivacyPolicy: false,
      }
    },
    
    created() {
      if (location.hash.includes('#sendTickets')) {
        this.contact = true
        this.ticketsContact = true
      }
    },
    
    methods: {

      home() {
        if (this.boxOffice.whiteLabelUrl) {
          window.open(this.boxOffice.whiteLabelUrl,'_blank');
        } else {
          window.location = "/";
        }
      },

      privacy() {
        if (this.boxOffice.privacyPolicy) {
          window.location = this.boxOffice.privacyPolicy;
        } else {
          this.showPrivacyPolicy = true;
        }
      },

      close() {
        this.questionContact = false; 
        this.ticketsContact = false;
        this.contactConfirm = false;
        this.sentTicketsConfirm = false,
        this.contact = false;
      },

      async send() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        this.sending = true;
        var payload = {};
        payload.name = this.name;
        payload.email = this.email;
        payload.message = this.message;
        if (this.event) {
          payload.id = this.event.id;
          Api.post(this, "Event", "sendEmail", payload);
        } else {
          payload.id = this.boxOffice.id;
          Api.post(this, "BoxOffice", "sendEmail", payload);
        }
        this.contactConfirm = true;
        this.sending = false;
      },

      async sendTickets() {
        if (! await Utils.validate(this.$refs.form) ) {
          return;
        }
        this.sending = true;
        var payload = {};
        payload.boxOfficeId = this.boxOffice.id;
        payload.email = this.email;
        let result = await Api.post(this, "BoxOffice", "emailFutureTickets", payload);
        this.emailSent = result.emailSent;
        this.sentTicketsConfirm = true;
        this.sending = false;
      },

    },

    computed: {
      
      utils: function () {
        return Utils;
      },
      cssVars: function () {
        if (this.boxOffice) {
          return {
            '--text-color': this.boxOffice.textColour,
            '--background-color': this.boxOffice.backgroundColour
          }
        }
      },

    },
  }
</script>

<style lang="scss" scoped>
.v-dialog {
  border-radius: 0px
}
.v-sheet.v-card {
  border: thick solid
}
.v-card__text, .v-card__title {
  word-break: normal /* maybe !important  */
}
.contact {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}
</style>

